
import { defineComponent, ref } from 'vue'
import { Icon, addIcon } from '@iconify/vue'
import arrowRight from '@iconify/icons-mdi/arrow-right'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'

addIcon('arrow-right', arrowRight)

export default defineComponent({
  components: {
    Icon
  },
  setup() {
    const store = useStore()
    const router = useRouter()
    const snapshotName = ref(store.state.iframe.snapshotName)

    const nextStep = () => {
      router.push({
        name: 'businessAddAccountMapPage'
      })
    }

    return {
      snapshotName,
      nextStep
    }
  }
})
