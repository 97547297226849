
import { defineComponent } from 'vue'
import BusinessInfoSideBar from '@/components/BusinessInfoSideBar.vue'
import BusinessSnapshot from '@/components/BusinessSnapshot.vue'
export default defineComponent({
  name: 'BusinessSnapshotPage',
  components: {
    BusinessInfoSideBar,
    BusinessSnapshot
  }
})
