<template>
  <div
    class="business-info-sidebar text-white font-normal text-center flex flex-col"
  >
    <div class="flex justify-center pt-16">
      <img
        v-if="!store.state.user.logo"
        src="@/assets/images/ghl-light-logo.svg"
        alt="ghl-logo"
      />
      <img v-else :src="store.state.user.logo" alt="ghl-logo" />
    </div>
    <div class="flex flex-col pt-40 lg:text-sm xl:text-base">
      <div>
        <span class="font-bold">{{ store.state.user.agencyName }}</span> is
        Everything
      </div>
      <div class="whitespace-nowrap">
        Your Agency Needs To Succeed
      </div>
    </div>
    <div class="mt-auto pb-12 lg:text-xs">
      <span class="block">We bring all the things you need to</span>
      <span class="block">solve your digital agency problems in</span>
      <span>one place.</span>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { useStore } from 'vuex'

export default defineComponent({
  setup() {
    const store = useStore()
    return {
      store
    }
  }
})
</script>

<style scoped type="scss">
.business-info-sidebar {
  background: linear-gradient(180deg, #182869 0%, #09174d 93.23%);
  min-width: 340px;
}

@media (max-width: 1024px) {
  .business-info-sidebar {
    display: none;
  }
}
</style>
