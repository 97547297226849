<template>
  <div class="form-parent-container">
    <div class="form-child-container">
      <div class="flex flex-col justify-center items-center">
        <div class="md:text-lg lg:text-xl xl:text-2xl font-medium text-center">
          <img src="@/assets/images/snapshot.svg" class="w-72 h-56" />
        </div>
        <div class="flex flex-row pt-10 font-normal text-lg text-center">
          <p>
            Your snapshot <b>"{{ snapshotName }}"</b> is loaded<br />into your account
          </p>
        </div>
        <div class="flex flex-row pt-20 font-normal text-base text-center">
          <p>
            Please continue and setup your account to access the snapshot
            settings
          </p>
        </div>
      </div>
      <div class="pt-16 flex flex-row justify-end items-end">
        <button
          class="base-filled-button indigo-btn flex justify-center items-center"
          @click="nextStep"
        >
          <span class="mr-1">Countinue to setup account</span>
          <Icon icon="arrow-right" height="20" />
        </button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue'
import { Icon, addIcon } from '@iconify/vue'
import arrowRight from '@iconify/icons-mdi/arrow-right'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'

addIcon('arrow-right', arrowRight)

export default defineComponent({
  components: {
    Icon
  },
  setup() {
    const store = useStore()
    const router = useRouter()
    const snapshotName = ref(store.state.iframe.snapshotName)

    const nextStep = () => {
      router.push({
        name: 'businessAddAccountMapPage'
      })
    }

    return {
      snapshotName,
      nextStep
    }
  }
})
</script>

<style scoped type="scss"></style>
